<template>
  <div class="app-related-section">
    <div
      class="app-related-section__container d-flex container justify-content-between align-items-center mb-5"
      data-aos="fade-up"
    >
      <h3 class="app-related-section__header">Related</h3>
    </div>

    <AppSwiper :data="items" :disable-padding-x="false" :related-mode="true" title-event="app-related" />
  </div>
</template>

<script>
import AppSwiper from '@/components/partials/elements/AppSwiper';

export default {
  name: 'AppRelatedSection',
  components: {
    AppSwiper,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    mainEntityId: {
      type: Number,
      default: null,
    },
    relatedToTitle: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.app-related-section {
  max-width: 1400px;
  margin: auto;
  padding: 80px 0 100px;
  border-top: 1px solid #d8d8d8;

  &__header {
    margin-bottom: 0;
    color: #363636;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 2.14px;
    line-height: 38px;
    text-transform: uppercase;
  }

  &__see-all {
    a {
      display: block;
      color: #363636;
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 1.11px;
      line-height: 26px;
      text-transform: uppercase;
      border-bottom: 1px solid #363636;

      &:hover {
        border-bottom: none;
      }
    }
  }
}
</style>
